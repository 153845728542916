import { Suspense } from 'react';
import { useOutlet } from 'react-router-dom';
import { AuthProvider } from './AdminProvider';

export const AuthLayout = () => {
  const outlet = useOutlet();

  return (
    <Suspense>
      <AuthProvider>{outlet}</AuthProvider>
    </Suspense>
  );
};
