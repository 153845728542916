export const SUPPORTED_LANGUAGES = [
  'en',
  'zhCN',
  'zhTW',
  'da',
  'nl',
  'fi',
  'fr',
  'de',
  'ind',
  'it',
  'ja',
  'ko',
  'no',
  'pl',
  'pt',
  'ru',
  'es',
  'esLA',
  'sv',
  'tr',
  'vi',
  'cs',
];

export const HEPHAESTUS_URL = window?._env_?.HEPHAESTUS_URL || 'http://localhost:8080';
export const GAME_S3_BUCKET = window?._env_?.GAME_S3_BUCKET || '';
