import axios, { AxiosInstance } from 'axios';

const DEFAUL_TIMEOUT_SERVER_INFO: number = 10000;

export interface INetworkResponse<T> {
  success: boolean;
  response: T;
  error?: string;
}

const APIKEY_HEADER = 'x-api-key';
enum HTTP_CODES {
  FORBIDEN = 403,
}

export default class PercyNetworkManager {
  private apiURL: string;
  private axiosInstance: AxiosInstance;
  private apiKey: string;

  constructor(apiURL: string, apiKey: string) {
    this.apiURL = apiURL;
    this.axiosInstance = axios.create();
    this.apiKey = apiKey;

    this.axiosInstance.interceptors.request.use(
      (config) => {
        config.headers[APIKEY_HEADER] = this.apiKey;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  handleExceptions(error: any) {
    let errorMessage = 'Unexpected when calling server';

    if (error?.response?.data?.error) {
      errorMessage = error?.response?.data?.error;
    }

    if (error?.response?.data?.message && Array.isArray(error?.response?.data?.message)) {
      errorMessage = error?.response?.data?.message?.join(', ');
    }

    if (typeof error?.response?.data?.message === 'string') {
      errorMessage = error?.response?.data?.message;
    }

    if (error?.response?.status === HTTP_CODES.FORBIDEN) {
      errorMessage = 'Forbidden request. Please check your Api Key before trying again';
    }

    return {
      data: {
        success: false,
        response: null,
        error: errorMessage,
      },
    };
  }

  updateBaseURL(newURL: string) {
    this.apiURL = newURL;
  }

  updateApiKey(apiKey: string) {
    this.apiKey = apiKey;
  }


  async getGamedataFromS3(gamedataURL: string, key: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await this.axiosInstance.get(`${gamedataURL}/${key}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/octet-stream;charset=utf-8',
        },
      });

      return { success: true, response: data };
    } catch (error) {
      return this.handleExceptions(error).data;
    }
  }

  async getGamedataFromS3_2(url: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await this.axiosInstance.get(url, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/octet-stream;charset=utf-8',
        },
      });

      return { success: true, response: data };
    } catch (error) {
      return this.handleExceptions(error).data;
    }
  }
  
}
