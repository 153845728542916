import { Navigate, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { lazy } from 'react';
import { AuthLayout } from './provider/AuthLayout';

// Views
const WrapperComponent = lazy(() => import('./pages/common/wrapperComponent').then(({ WrapperComponent }) => ({ default: WrapperComponent })));
const Home = lazy(() => import('./pages/home/Home').then(({ Home }) => ({ default: Home })));
const Auth = lazy(() => import('./pages/auth/Auth').then(({ Auth }) => ({ default: Auth })));
const Hephaestus = lazy(() => import('./pages/hephaestus/Hephaestus').then(({ Hephaestus }) => ({ default: Hephaestus })));
const ComparitionTool = lazy(() => import('./pages/hephaestus/ComparitionTool').then(({ ComparitionTool }) => ({ default: ComparitionTool })));
const HephaestusBuild = lazy(() => import('./pages/hephaestus/HephaestusBuild').then(({ HephaestusBuild }) => ({ default: HephaestusBuild })));
const GamedataDetails = lazy(() => import('./pages/hephaestus/GamedataDetails').then(({ GamedataDetails }) => ({ default: GamedataDetails })));
const GamedataFromS3 = lazy(() => import('./pages/hephaestus/GamedataFromS3').then(({ GamedataFromS3 }) => ({ default: GamedataFromS3 })));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />}>
      {/* Public routes */}
      <Route>
        <Route path="/auth" element={<Auth />} />
        <Route path="/" element={<Navigate to="/auth" />} />
      </Route>

      {/* Private routes */}
      <Route element={<WrapperComponent />}>
        <Route path="/home" element={<Home />} />
        <Route path="/hephaestus" element={<Hephaestus />} />
        <Route path="/hephaestus/gamedata/comparison" element={<ComparitionTool />} />
        <Route path="/hephaestus/gamedata/build" element={<HephaestusBuild />} />
        <Route path="/hephaestus/gamedata/details/:id" element={<GamedataDetails />} />
        <Route path="/hephaestus/gamedata/s3" element={<GamedataFromS3 />} />
      </Route>
    </Route>,
  ),
);
