import axios from 'axios';

export interface INetworkResponse<T> {
  success: boolean;
  response: T;
  error?: string;
}

export default class CommonNetworkManager {
  private handleExceptions(error: any) {
    let errorMessage = 'Unexpected error connecting with GiLab server';

    if (error?.response?.data?.message && Array.isArray(error?.response?.data?.message)) {
      errorMessage = error?.response?.data?.message?.join(', ');
    }

    if (typeof error?.response?.data?.message === 'string') {
      errorMessage = error?.response?.data?.message;
    }

    return {
      success: false,
      response: null,
      error: errorMessage,
    };
  }

  async getGitLabUser(accessToken: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.get('https://gitlab.com/api/v4/user', {
        headers: { 'PRIVATE-TOKEN': accessToken },
      });
      return { success: true, response: data };
    } catch (error) {
      return this.handleExceptions(error);
    }
  }

  async getGitLabToken(accessToken: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.get('https://gitlab.com/api/v4/personal_access_tokens/self', {
        headers: { 'PRIVATE-TOKEN': accessToken },
      });
      return { success: true, response: data };
    } catch (error) {
      return this.handleExceptions(error);
    }
  }
}
