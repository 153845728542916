import axios from 'axios';
import { IHephaestusBuildData } from '../interfaces/hephaestus';

interface IGamedataList {
  lastGamedataItem?: string;
  gamedataKey?: string;
}

export interface INetworkResponse<T> {
  success: boolean;
  response: T;
  error?: string;
}

export default class HephaestusNetworkManager {
  private apiURL: string;

  constructor(apiURL: string) {
    this.apiURL = apiURL;
  }

  private handleExceptions(error: any) {
    let errorMessage = 'Unexpected error when calling hephaestus server';

    if (error?.response?.data?.message && Array.isArray(error?.response?.data?.message)) {
      errorMessage = error?.response?.data?.message?.join(', ');
    }

    if (typeof error?.response?.data?.message === 'string') {
      errorMessage = error?.response?.data?.message;
    }

    return {
      success: false,
      response: null,
      error: errorMessage,
    };
  }

  async getGamedatalist({ lastGamedataItem, gamedataKey }: IGamedataList): Promise<INetworkResponse<any>> {
    let requestUrl = `${this.apiURL}/gamedata/list?`;

    if (lastGamedataItem) {
      requestUrl += `lastGamedataItem=${lastGamedataItem}`;
    }

    if (gamedataKey) {
      requestUrl += `gamedataKey=${gamedataKey}`;
    }

    try {
      const { data } = await axios.get(requestUrl);
      return { success: true, response: data };
    } catch (error) {
      return this.handleExceptions(error);
    }
  }

  async getGamedataId(gamedataVersion: string): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.get(`${this.apiURL}/gamedata/${gamedataVersion}`);
      return { success: true, response: data };
    } catch (error) {
      return this.handleExceptions(error);
    }
  }

  async buildGamedata(buildinfo: IHephaestusBuildData): Promise<INetworkResponse<any>> {
    try {
      const { data } = await axios.post(
        `${this.apiURL}/gamedata/build`,
        {
          ...buildinfo,
        },
        { timeout: 30000 },
      );

      return { success: true, response: data };
    } catch (error) {
      return this.handleExceptions(error);
    }
  }
}
