import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { HEPHAESTUS_URL } from '../pages/common/constants';
import { useCookie } from '../hooks/useCookie';

// Utils
import NetworkManager from '../services/percyNetworkManager';
import HephaestusNetworkManager from '../services/hephaestusNetworkManager';
import CommonNetworkManager, { INetworkResponse } from '../services/commonNetworkManager';

interface IUserSession {
  email: string;
  name: string;
  avatarURL: string;
}

const AuthContext = createContext<{
  networkManager: NetworkManager;
  hephaestusNetworkManager: HephaestusNetworkManager;
  updateApiKey: (apikey: string) => void;
  setAccessToken: (accessToken: string) => Promise<INetworkResponse<any>>;
  percyApikey: string;
  userSession: Partial<IUserSession>;
  clearUserSession: () => void;
}>(null!);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [percyApikeyCookies, setPercyApikeyCookies] = useCookie('percyApikey', '');
  const [percyApikey, setPercyApikey] = useState(percyApikeyCookies);

  const [gitlabAccessTokenCookies, setGitlabAccessTokenCookies] = useCookie('gitlabAccessToken', '');
  const [userSession, setUserSession] = useState<Partial<IUserSession>>({} as IUserSession);

  const [searchParams, setSearchParams] = useSearchParams();
  const URLEnvironment = "";

  const setAccessToken = async (accessToken: string) => {
    const commonNetworkManager = new CommonNetworkManager();

    const gitlabUserResult = await commonNetworkManager.getGitLabUser(accessToken);

    if (!gitlabUserResult.success) {
      return gitlabUserResult;
    }

    const { email, name, avatar_url: avatarURL } = gitlabUserResult.response;

    const tokenResult = await commonNetworkManager.getGitLabToken(accessToken);

    if (!tokenResult.success) {
      return tokenResult;
    }

    const tokenExpirationInMinutes = (new Date(tokenResult.response.expires_at).getTime() - Date.now()) / 60_000; // 60,000 ms in 1 min;

    setUserSession({ email, name, avatarURL });
    setGitlabAccessTokenCookies(accessToken, tokenExpirationInMinutes);
    return gitlabUserResult;
  };

  const clearUserSession = () => {
    setGitlabAccessTokenCookies('', 0);
    setUserSession({});
  }

  useEffect(() => {
    // If the session is empty, but the access token cookie exists, set the session from the cookie.
    if(!Object.entries(userSession).length && gitlabAccessTokenCookies) {
      setAccessToken(gitlabAccessTokenCookies);
    }
  }, [gitlabAccessTokenCookies, userSession]);

  const updateApiKey = (apikey: string) => {
    const expireApikeyInMinutes = 120;
    setPercyApikeyCookies(apikey, expireApikeyInMinutes);
    setPercyApikey(apikey);
    networkManager.updateApiKey(apikey);
  };

  const networkManager = new NetworkManager("", percyApikey);
  const hephaestusNetworkManager = new HephaestusNetworkManager(HEPHAESTUS_URL);

  const value = {
    networkManager,
    hephaestusNetworkManager,
    percyApikey,
    userSession,
    updateApiKey,
    setAccessToken,
    clearUserSession,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

export const RequireAuth = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};
