import { useState } from 'react';

const getItem = (key: string) => {
  const cookies = document.cookie.split('; ');

  for (let cookie of cookies) {
    const [storedKey, ...rest] = cookie.split('=');
    const storedValue = rest.join('=');

    if (key === storedKey.trim()) {
      return decodeURIComponent(storedValue);
    }
  }

  return '';
};

const setItem = (key: string, value: string, minutes: number) => {
  const now = new Date();
  now.setTime(now.getTime() + Number(minutes) * 60000);
  
  document.cookie = `${key}=${encodeURIComponent(value)}; expires=${now.toUTCString()}; path=/`;
};

export const useCookie = (key: string, defaultValue: string) => {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value: string, minutes: number) => {
    setCookie(value);
    setItem(key, value, minutes);
  };

  return [cookie, updateCookie] as const;
};
